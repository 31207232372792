import axios from "axios"
// cms
import { CmsUserStore } from '@/store/api_v1/cms'
import { URL_LOGOUT } from '@/services/api_v1/cms/Auth.service'
const cmsApiUrl = '/api/v1/cms'

// patient
import { PatientPatientStore } from '@/store/api_v1/patient'
import { URL_LOGOUT as PATIENT_URL_LOGOUT } from '@/services/api_v1/patient/Auth.service'
const patientApiUrl = '/api/v1/patient'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
})

// request
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url.startsWith('/auth/')) { // cms
      const token = localStorage.getItem('access_token') || CmsUserStore.state.user?.access_token
      // TODO if (!token) return redirect({ name: 'login' })
      if (!!token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    if (config.url.startsWith(cmsApiUrl)) { // cms
      const token = localStorage.getItem('access_token') || CmsUserStore.state.user?.access_token
      // TODO if (!token) return redirect({ name: 'login' })
      if (!!token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    if (config.url.startsWith(patientApiUrl)) { // patient
      const patientToken = localStorage.getItem('access_token') || PatientPatientStore.state.patient?.access_token
      // TODO if (!patientToken) return redirect({ name: 'login' })
      if (!!patientToken) {
        config.headers['Authorization'] = `Bearer ${patientToken}`
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.config.url.startsWith(cmsApiUrl)) { // cms
      if (error.response.status === 401 && error.response.config.url !== URL_LOGOUT) {
        await $store.dispatch('CmsUserStore/logout')
          .then((response) => {
            $store.commit('CmsUserStore/resetUser')
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            window.location.href = '/auth'
          }).catch((error) => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            window.location.href = '/auth'
          })
      }
    }
    if (error.response.config.url.startsWith(patientApiUrl)) { // patient
      if (error.response.status === 401 && error.response.config.url !== PATIENT_URL_LOGOUT) {
        await $store.dispatch('PatientPatientStore/logout')
          .then((response) => {
            $store.commit('PatientPatientStore/resetPatient')
            $store.commit('PatientPatientStore/resetTest')
            localStorage.removeItem('access_token')
            if($router?.currentRoute?.value?.name === 'patient.auth') return
            window.location.href = '/auth'
          })
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance
